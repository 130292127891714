import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import './Home.css';
import './Color.css';
import {Helmet} from 'react-helmet'
import Footer from '../layouts/Footer';

import { useSpring, animated } from 'react-spring';


//Google Analytics 4 Implementation Sahil
import ReactGA from 'react-ga4'; 
ReactGA.send({ hitType: "pageview", page: "./Home" });

let sizeTagLine = 50;

function resize() {
    if ( window.innerWidth < 600) {     
        sizeTagLine = 25
    }
    else {
        sizeTagLine = 40
    }
}

const SpringTextSahil = () => {
    resize();
    const Scale = useSpring({
        from: {
            fontSize: 2
        },
        to: {
            
            fontSize: sizeTagLine
        }
    });

    return(
                <div>
                    
                    <animated.p className="tagLine1" style={Scale}>
                    <span className="colorGreen">" Learning </span>
                    <span className="colorRed">Today, </span>
                    <span className="colorBlue">Leading </span>
                    <span className="colorOrange">Tomorrow! "</span>
                    </animated.p>

                </div>
    )
}

const AboutStMary = () => {
    return(
        <div className="whyStMary">
            <span className="addShadow">
            <h1> WHY ST. MARY'S?</h1>
            </span>
            <p className="whyStMaryText"> 
            <li>Fun Frolic Activities for Kids</li>
            <li>Experienced Teachers</li>
            <li>Private Garden within School Premise</li>
            <li>Fully Air-Conditioned Rooms</li>
            <li>English Medium</li>
            </p>
            <p className="whyStMaryText"> Since 1968, St. Mary's Kindergarten School has been entrusted by people of India to guide their children. 
            Experienced tutelage of St. Mary's Kindergarten School nurtures students till they are fully prepared to move on to class-1 in the senior wing.</p>
            <p className="whyStMaryText"> St. Mary's Kindergarten School is now situated on Tosham Road, near ITI, Hisar.</p>

            <p> Mission </p>
            <p className="whyStMaryText"> Our mission is to provide a safe, disciplined, learning environment 
                that empowers all students to develop their full potential. </p>
            <p> Vision </p>
            <p className="whyStMaryText"> Raising a child is a team effort. With a strong team supporting them, every child can learn.</p> 
        </div>
    )
}
export default class Home extends Component {
  
    constructor() {
        super()
        this.comingSoon=this.comingSoon.bind(this)
      }
      comingSoon=(e)=>{
          alert("Shoot En Stunt Coming Soon!");
      }     
            
    render() {
    
        return (
            <div className="rootHome">
                <Helmet>
                <title>St. Mary Kindergarten School | Best Play School in Hisar</title>
                <meta name="description"
                content="Since 1968, St. Mary's Kindergarten School has been educating children of Hisar. We are a play school, where kids have fun and learn. Classes: Pre-Nursery, Nursery, LKG and UKG."/>
                <meta name="robots" content="index, follow" />
        <meta
          name="googlebot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta
          name="bingbot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
                </Helmet>
                <div className="Header">
                <img className="headerImg" src={require("../images/HeaderHome3.jpg")} alt="St. Mary's Play School"/>
            
                </div>

                <SpringTextSahil />

                <AboutStMary />
                
                <div className="linkCards">
                <Grid container spacing={1} columns={16}>     
                
                    <Grid item xs={12} >
                        <div className="gridElement">
                            <p className="cardHeaderText">CLASSES</p>
                            </div>
                    </Grid>
                    
                    <Grid item xs={12} >
                        <div className="gridElement">
                            <img className="portImg" src={require('../images/prenursery.jpg')} alt="St. Mary Pre-Nursery"/>
                            <div className="colorBackgroundOrange">
                            <p className="cardSubheadertxt">PRE-NURSERY</p>
                            
                            <p className="cardParatxt">Children start to explore the world around them as soon as they are born.</p>
                            <p className="cardAgetxt"></p>
                            </div>
                        </div>
                        </Grid>
                       
                       <Grid item xs={12}>
                        <div className="gridElement">
                            <img className="portImg" src={require('../images/nursery.jpg')} alt="St. Mary Nursery"/>
                            <div className="colorBackgroundGreen">
                            <p className="cardSubheadertxt">NURSERY</p>
                            <p className="cardParatxt">Where playing allows children to learn without having to go through pressure.</p>
                            <p className="cardAgetxt"></p>
                            </div>

                        </div>
                        </Grid>
              
                        <Grid item xs={12}>
                        <div className="gridElement">
                            <img className="portImg" src={require('../images/lkg.jpg')} alt="St. Mary UKG "/>
                            <div className="colorBackgroundBlue">
                            <p className="cardSubheadertxt">LKG</p>
                            <p className="cardParatxt">The students are taught the basic skills such as reading, writing, speaking, etiquettes etc.</p>
                            <p className="cardAgetxt"></p>
                            </div>
                        </div>
                        </Grid>

                        <Grid item xs={12}>
                        <div className="gridElement">
                            <img className="portImg" src={require('../images/ukg.jpg')} alt="St. Mary LKG"/>
                            <div className="colorBackgroundRed">
                            <p className="cardSubheadertxt">UKG</p>
                            <p className="cardParatxt">They learn about formation of words, simple addition, number patterns and a lot more.</p>
                            <p className="cardAgetxt"></p>
                            </div>

                        </div>
                        </Grid>
                 
                </Grid>
                </div>

                
                <Footer />
            </div>
            
        )
    }
}
