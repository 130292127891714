import React, { Component } from 'react'
import './Apply.css';
import Grid from '@material-ui/core/Grid';
import {Helmet} from 'react-helmet';
import Recaptcha from 'react-google-invisible-recaptcha';
import * as emailjs from 'emailjs-com';
import Footer from '../layouts/Footer';

//Google Analytics 4 Implementation Sahil
import ReactGA from 'react-ga4'; 
ReactGA.send({ hitType: "pageview", page: "./Apply" });


export default class Apply extends Component {
    constructor(){
        super();
        this.state={
            FirstName:'',
            LastName:'',
            Email:'',
            ConfirmEmail:'',
            Role:'', 
        }
        this.handelFirstNameChange=this.handelFirstNameChange.bind(this)
        this.handelLastNameChange=this.handelLastNameChange.bind(this)
        this.handelEmailChange=this.handelEmailChange.bind(this)
        this.handelConfirmEmailChange=this.handelConfirmEmailChange.bind(this)
        this.handelRoleChange=this.handelRoleChange.bind(this)
    }
    handelFirstNameChange = (e) =>{
        this.setState({
            FirstName:e.target.value
        })
    }
    handelEmailChange = (e) =>{
        this.setState({
            Email:e.target.value
        })
    }
    handelConfirmEmailChange = (e) =>{
        this.setState({
            ConfirmEmail:e.target.value
        })
    }
    handelLastNameChange = (e) =>{
        this.setState({
            LastName:e.target.value
        })
    }
    handelRoleChange = (e) =>{
        this.setState({
            Role:e.target.value
        })
    }
    showSubmittedText=(e)=>{
        var x = document.getElementById('submittedText');
        x.style.display='block'
        var y = document.getElementById('rightapply');
        y.style.display='none'
    }
    handleSubmit=(e)=>{
        e.preventDefault()
       const {FirstName,LastName, Email, ConfirmEmail,Role } = this.state
       if(FirstName.length && Email.length && Role.length)
       if(Email==ConfirmEmail)
      {  
          emailjs.sendForm(
            "service_nqegey2",
            "template_ktgpqbq",
            "AplicationForm",
            "r-HMomzlBMBtSXWTd"
        ).then()
        .catch();
        console.log("email sent !")
        this.setState({
            FirstName:'',
            LastName:'',
            Email:'',
            ConfirmEmail:'',
            Role:'',
        });
        this.recaptcha.execute();
        this.showSubmittedText();
    }
    else {
        alert("Email couldn't be confirmed!");
        this.recaptcha.reset();
    }
        else {
            alert("Validation failed! Input cannot be empty");
            this.recaptcha.reset();
        }
         
    
     };
    render() {
        return (
            <div className="root">
                 <Helmet>
                    <title>Apply Now | Careers</title>
                    <meta name="description"
      content="Apply for jobs in our school today"/>
                </Helmet>
                
                <Grid container spacing={0}>

                <Grid item xs={12} sm={6}className="leftapply">
                        <h1 className="headingApply">START WITH YOUR APPLICATION!</h1>
                         
                     </Grid>
                <Grid item xs={12} sm={6} className="rightapply" id="rightapply">
                    <form className="formapply" id="AplicationForm"> 
                       <p className="label required">NAME</p>   
                       <input className="formin"
                       required
                         value="FirstName"
                           label="FirstName" 
                           name="FirstName"
                           id="FirstName"
                           placeholder="First Name"
                           value={this.state.FirstName}
                           type='text' 
                           onChange={this.handelFirstNameChange.bind(this)}/>
                        <input className="formin"
                        value="LastName"
                        label="LastName" 
                        name="LastName"
                        id="LastName"
                        placeholder="Last Name"
                        value={this.state.LastName}
                        type='text' 
                        onChange={this.handelLastNameChange.bind(this)}/>
    
                    <p className="label required">EMAIL</p>
                  
                        <input className="formin"
                        required
                         value="Email"
                           label="Email" 
                           name="Email"
                           id="Email"
                           placeholder="Email"
                           value={this.state.Email}
                           type='text' 
                           onChange={this.handelEmailChange.bind(this)}/>
                        <input 
                        className="formin"
                        required
                        placeholder="Confirm Email"
                        value="ConfirmEmail"
                          label="ConfirmEmail" 
                          name="ConfirmEmail"
                          id="ConfirmEmail"
                          value={this.state.ConfirmEmail}
                          type='text' 
                          onChange={this.handelConfirmEmailChange.bind(this)}/>
                          <p className="label required">ROLE IN TEAM</p>
                  
                  <input className="formin"
                  required
                   value="Role"
                     label="Role" 
                     name="Role"
                     id="Role"
                     placeholder="Which Role are you Interested in?"
                     value={this.state.Role}
                     type='text' 
                     onChange={this.handelRoleChange.bind(this)}/>
                         <button type="submit" onClick={this.handleSubmit.bind(this)} className="submitbt">SUBMIT</button>
                    </form>
                     </Grid>
                     
              <Grid xs={12} sm={6} className="submittedText" id="submittedText">Thanks for contacting us! We will be in touch with you shortly.</Grid>
                     </Grid>
           
                     <Recaptcha
          ref={ ref => this.recaptcha = ref }
          sitekey="6LcjpJcjAAAAALCsZZ8JgclWP67mfIYmjeynD3wt"
          onResolved={ () => console.log( 'Human detected.' ) }
          />    
          <Footer/>
            </div>
        )
    }
}
