import React, {useState} from 'react';
import './App.css';

import Navbar from './components/layouts/Navbar';
import Home from './components/Pages/Home';
import Footernew from './components/layouts/Footer';
//import QuestionAnswerRoundedIcon from '@material-ui/icons/QuestionAnswerRounded';

import {
  Routes,
  Route, 
  Link,
} 
from 'react-router-dom';

//import Chatbox from './components/layouts/Chatbox';
import Contact from './components/Pages/Contact';
import Careers from './components/Pages/Careers';
import Apply from './components/Pages/Apply';
import Faculty from './components/Pages/Faculty';
import Gallery from './components/Pages/Gallery';

function App() {
 // const [show, setshow]=useState(false);
 // const openChat=()=>{
  //  this.state({show:true});
 //     setshow(!show);
 // };

  return (
    <div className="App">
      {/* <CssBaseline /> */}
      <Navbar>
      </Navbar>
      
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/Home" element={<Home />} />
        <Route exact path="/Careers" element={<Careers />} />
        <Route exact path="/ContactUS" element={<Contact />} />
        <Route exact path="/Apply" element={<Apply />} />
        <Route exact path="/Faculty" element={<Faculty />} />
        <Route exact path="/GalleryStMarySchool" element={<Gallery />} />

      </Routes>
      {/* <Chatbox /> */}
      {/* <FooterNew /> */}
    </div>
  );
}

export default App;
