import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styled from "styled-components";
import './footer.css';
//import Link from '@material-ui/core/Link';
import { Typography,  List, ListItem } from '@material-ui/core';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import MailIcon from '@material-ui/icons/Mail';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import WhatsAppIcon from '@material-ui/icons/WhatsApp';
// import instagram from'../images/instagram.png';
const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // marginTop:"-1000px", /*-10px*/
    padding: '8px 16px 0 16px',
    fontFamily:"Roboto-Medium"
  },

  subFooter:{
   textAlign:"middle",
   padding:"0",
   // marginTop:"6%",
   fontFamily:"Roboto-Medium",
   fontSize:"1vw",
   zIndex:"0",
      
  },
  title:{
      fontSize:"2vw",
      paddingBottom: '1.5vw',
      fontFamily:"Roboto-Medium", /*CaviarDreams*/
     // marginTop:"",
      fontWeight:"bold",
      [theme.breakpoints.up('xs')]: {
       fontSize:"4vw",
       },
      [theme.breakpoints.up('sm')]: {
     //   marginTop:"0vw",
       fontSize:"1.85vw",
        
      },
  },
  subTitle:{
    fontSize:"1.3vw",
    fontFamily:"Roboto-Medium",
    marginTop:"0",
    textAlign:"justify",
    fontWeight:"400",
    [theme.breakpoints.up('xs')]: {
      fontSize:"2vw",
      marginTop:"0%",
     },
    [theme.breakpoints.up('sm')]: {
         marginTop:"0",
         fontSize:"1.6vw",
         marginBottom:"0",
    },
  },

  linklist:{
        listStyle: 'none',
        paddingTop: '35px',
        marginLeft:"100px",
        [theme.breakpoints.down('sm')]: {
          marginLeft:"0px",

        },
  },
  links:{
    lineHeight: '2',
    color: "black",
    textDecoration: "none",
    fontSize:"20px", 
    '&:hover':{
        color:'gray',
        listStyle:"url('../images/tringal.png')"
    }
  },
  
  leftgrid:{

    textAlign:"left",
    marginTop:"10%",
    zIndex:"1",
    [theme.breakpoints.between('xs','sm')]: {
      marginTop:"10%",
      width:"100%",
     
     },
     [theme.breakpoints.down('sm')]: {
      
      marginLeft:"0%",
     },
    [theme.breakpoints.up('sm')]: {
      marginTop:"2.5%",
      marginBottom:"-5%",
    },
    [theme.breakpoints.up('md')]: {
      marginTop:"2.5%",
      marginBottom:"-5%",
    },
  },
  middlegrid:{
   
    textAlign:"left",
    marginTop:"5.2%",
    zIndex:"1",

    [theme.breakpoints.between('xs','sm')]: {
     marginTop:"5%",
     borderTop:"4px solid white",
    },
    [theme.breakpoints.up('sm')]: {
      marginTop:"5.2%",
      paddingTop:"0%",
      borderTop:"none",
    },
  
  },
  rightgrid:{
  
    textAlign:"left",
    marginTop:"0%",
    zIndex:"1",

    [theme.breakpoints.up('xs')]: {
    borderTop:"5px solid white",
    marginTop:"0",
    },
    [theme.breakpoints.up('sm')]: {
      marginTop:"5%",
      paddingTop:"0%",
      borderTop:"none", 
    },
  },

  social:{
    fontFamily:"Fredoka One",
    margin:"2%",
    fontSize: "1.5vw",
    letterSpacing:'0.1vw',
    "&:hover":{
      color:"gray",
    },
    [theme.breakpoints.up('xs')]: {
    fontSize: "1.3vw",
    margin:"0",
    marginLeft:"5%",
    },
    [theme.breakpoints.up('sm')]: {
     
    },
  }
}));
const FooterContainer = styled.div`
  bottom: 0;
  color: black;
   `;

export default function FullWidthGrid() {
  const classes = useStyles();
  return (
    <FooterContainer>
    <div className="footerroot">

     <div>
      <Grid  container spacing={4}>
        
        <Grid className={classes.leftgrid}  item xs={12} sm={4}>
          <div className="LeftGrid">
        <Typography className={classes.title} >ST. MARY'S KINDERGARTEN SCHOOL</Typography>
                <p className={classes.subTitle}>St. Mary's is a play school that provides learning experience in language development, number concepts, creative skills, and social and physical growth within 2 to 6 years aged kids.</p>           
                </div>
        </Grid>
        <Grid className="middlegrid" className={classes.middlegrid} item xs={12} sm={4}>
             <ul className="linklist" >
             <li><img className="linksIcon" src={require("../images/tringal.png")}/><a className="links"href="/Faculty">FACULTY</a></li>
             <li><img className="linksIcon" src={require("../images/tringal.png")}/><a className="links"href="/GalleryStMarySchool">GALLERY</a></li>
             <li><img className="linksIcon" src={require("../images/tringal.png")}/><a className="links"href="/PrivacyPolicy">ADMISSION</a></li>
             </ul>
        </Grid >
        <Grid className={classes.rightgrid} item xs={12} sm={4}>
        <List component="nav" aria-labelledby="nested-list-subheader">
      
      <ListItem>
        
        <img className="socialicon" src={require("../images/locationIcon.jpg")} alt="mapIcon"/>
     
        <a className="social">New Model Town Extension, <br/>Tosham Road, near ITI <br />Hisar - 125005 
        <br/> M: 79882-25799 <br/> Ph: 01662-250454 <br/> st.mary250454@gmail.com</a>

      </ListItem>
    </List>
        </Grid>
        <Grid item xs={12} sm={12} style={{zIndex:"1",padding:"5px"}}>
        <div  className="subFooter" >
          <p> Copyright © 2023 St. Mary's Kindergarten School. All Rights Reserved.</p>
        </div>
        </Grid>
      </Grid>
      {/* <div  className="subFooter" >
          <p> Copyright © 2020 Western Vindhya. All Rights Reserved.</p>
        </div> */}
      </div>
    </div>
    </FooterContainer>
  );
}
