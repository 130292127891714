import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import './career.css';
import {Helmet} from 'react-helmet';
import Footer from '../layouts/Footer';

import { useSpring, animated } from 'react-spring';

//Google Analytics 4 Implementation Sahil
import ReactGA from 'react-ga4'; 
ReactGA.send({ hitType: "pageview", page: "./Careers" });

const Sahiltest = () => {
    const Scale = useSpring({
        from: {
            fontSize: 2
        },
        to: {
            fontSize: 40
        }
    });

    return(
                <div className="whatweoffer">
                    <animated.h1 className="headings" style={Scale}> WHAT WE OFFER?</animated.h1>
                    <div className="grayblock">
                        <p className="blocktext">CANDIDATE: WHY WORK WITH YOU?</p>
                        <p className="blocktext">ST. MARY: WITH ME YOU HAVE THE OPPORTUNITY AND THE RIGHT SET 
                             OF TOOLS, TO DEVELOP THE VERY BEST YOU CAN GIVE TO THIS WORLD.</p>
                    </div>
                    <h1 className="headings" >HISAR</h1>
                </div>
    )
}

export default class Careers extends Component {
    render() {
        return (
            <div className="root">
                <Helmet>
                    <title>Careers | Work at St. Mary'S Kindergarten School</title>
                    <meta name="description"
      content="Do you want to help in creating leaders of future? Join us in our journey today."/>
                <meta name="robots" content="index, follow" />
        <meta
          name="googlebot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta
          name="bingbot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
                </Helmet>
                <div className="Header">
                    <img className="headerImg" src={require("../images/headder.jpg")} alt="header"/>
                    
                    <h1 className="Heading">CAREERS</h1>
                    <h1 className="subheading">A SCHOOL THAT EMPOWERS YOU TO TEACH THE BEST</h1>
                </div>
                <Sahiltest></Sahiltest>
                <div className="gurugram">
                    <img className="gurugramImg"src={require("../images/gurugram-icom-min.png")} alt="Hisar"/>
                    <ul className="GList">
                        <li className="glistitem"><img src={require("../images/TRINGEL-min.png")} className="triangle-right"/> Taste of City Life</li>
                        <li className="glistitem"><img src={require("../images/TRINGEL-min.png")} className="triangle-right"/> Top Notch Education System</li>
                        <li className="glistitem"><img src={require("../images/TRINGEL-min.png")} className="triangle-right"/> Green Societies and Neighbourhoods to Live in</li>
                        <li className="glistitem"><img src={require("../images/TRINGEL-min.png")} className="triangle-right"/> Developing Airport, PLA Market, Rajguru Market, Deer Park</li>
                    </ul>
                </div>
                <div className="People">
                    <h1 className="headings"> PEOPLE AT ST. MARY'S KINDERGARTEN SCHOOL</h1>
                    <p>Highly passionate and talented, we people aim to develop habits in children, through which they can develop a better peaceful society. Individually, we are the best in our skill set and as a team, we make the possibly best to happen.</p>
                </div>
                <div className="openings">
                    <h1 className="headings">OPENINGS</h1>
                    <div  className="openingGrid">
                    <Grid container spacing={10}>
                    <Grid className="gridItem"item xs={12} sm={4}>
                     <div className="job"> 
                        <div id="Upper"  className="Upper">TEACHER</div>
                        <div id="Down"className="Down">
                            <h1 className="desHead">PRE-NURSERY</h1>
                            
                        <p className="descriptionList">
                        {/* <li className="deslistItem">2+ years experience</li>
                        <li className="deslistItem">Full-time team member</li> */}
                        &#9724;&#xfe0e;  5+ years experience &#9724;&#xfe0e;  Full-time
                        </p>
                        <button className="applydes" onClick={event =>  window.location.href='/Apply'}style={{ marginTop:'24%'}}  variant="contained">
                                  APPLY
                            </button>
                        </div> 
                     </div>
                 </Grid>
                    <Grid className="gridItem"item xs={12} sm={4}> 
                    <div className="job"> 
                    <div className="Upper">TEACHER</div>
                    <div className="Down">
                    <h1 className="desHead">NURSERY</h1>

                    <p className="descriptionList">
                        {/* <li  className="deslistItem">2+ years experience</li>
                        <li className="deslistItem">Full-time team member</li>
                        <li className="deslistItem">Portfolio is required</li> */}
                            &#9724;&#xfe0e;  5+ years experience &#9724;&#xfe0e;  Full-time
                        </p>
                        <button className="applydes" onClick={event =>  window.location.href='/Apply'}style={{marginTop:'24%' }} color="primary" variant="contained">
                                  APPLY
                            </button>
                    </div> 
                     </div>
                    </Grid>
                    
                    <Grid className="gridItem"item xs={12} sm={4}>
                    <div className="job"> 
                         <div className="Upper">TEACHER</div>
                         <div className="Down">
                         <h1 className="desHead">LKG</h1>
                             <p className="description">
                        Must have prior experience in teaching young learners</p>
                        <button className="applydes" onClick={event =>  window.location.href='/Apply'}style={{ marginTop:'23%' }}color="primary" variant="contained">
                                  APPLY
                            </button>
                             </div> 
                     </div>
                         </Grid>
                    <Grid className="gridItem"item xs={12} sm={4}>
                    <div className="job"> 
                         <div className="Upper">Teacher</div>
                         <div className="Down">
                         <h1 className="desHead">UKG</h1>
                             
                         <p className="descriptionList" >
                        {/* <li  className="deslistItem">5+ years experience</li>
                        <li className="deslistItem">Part-time team member</li> */}
                        &#9724;&#xfe0e;  5+ years experience &#9724;&#xfe0e;  Full-time 
                        </p>
                        <button className="applydes" onClick={event =>  window.location.href='/Apply'}style={{  marginTop:'0vw' }}color="primary" variant="contained">
                                  APPLY
                            </button>
                             </div> 
                     </div>
                         </Grid>
                    <Grid className="gridItem"item xs={12} sm={4}>
                    <div className="job"> 
                         <div className="Upper">MARKETING</div>
                         <div className="Down">
                             <h1 className= "desHead">JUNIOR MARKETING</h1>
                        <p className="description">
                        Handle SEO and Social Media Channels.
                        </p>
                        <button className="applydes" href='/AppDev' style={{ marginTop:'28%' }} onClick={event =>  window.location.href='/Apply'} color="primary" variant="contained">
                                  APPLY
                            </button>
                             </div> 
                     </div>
                         </Grid>
                    </Grid>
                    </div>
                   
                    <p className="quote">“Happiness is the feeling that the world needs your work”</p>
                </div>
                <Footer/>
            </div>
        )
    }
}
