import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import './Gallery.css';
import './Color.css';
import {Helmet} from 'react-helmet'
import Footer from '../layouts/Footer';

import { useSpring, animated } from 'react-spring';


//Google Analytics 4 Implementation Sahil
import ReactGA from 'react-ga4'; 
ReactGA.send({ hitType: "pageview", page: "./Gallery" });

let sizeColumn = 3;


function numberOfColumns() {
    if ( window.innerWidth < 900) {     
        sizeColumn = 3
    }
    else {
        sizeColumn = 5
    }
}

function StandardImageList() {
    numberOfColumns();
    return (
    /*  <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}> */
        <ImageList className="imageListSahil" cols={sizeColumn} >
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    );
  }
  
  const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
    },
    {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
    },
  ];


export default class Gallery extends Component {
            
    render() {
    
        return (
            <div>
                <div className="Header">
                <img className="headerImg" src={require("../images/HeaderHome3.jpg")} alt="St. Mary's Play School"/>
            
                </div>

                <StandardImageList />
                
                <Footer />
            </div>
        )
    }
}
