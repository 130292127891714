import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import './Faculty.css';
import './Color.css';
import {Helmet} from 'react-helmet'
import Footer from '../layouts/Footer';

import { useSpring, animated } from 'react-spring';


//Google Analytics 4 Implementation Sahil
import ReactGA from 'react-ga4'; 
ReactGA.send({ hitType: "pageview", page: "./Faculty" });

export default class Faculty extends Component {
            
    render() {
    
        return (
        
            <h1>IN DEVELOPMENT...</h1>

            
        )
    }
}
