import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';

import './index.css';
import App from './App';
import './fonts/Humanist777BoldCondensedBT.ttf';
import * as serviceWorker from './serviceWorkerRegistration'; // Sahil, there might be issue. It is different from Westvindhw website
import * as ReactDomClient from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
const root = ReactDomClient.createRoot(document.getElementById("root"))
root.render(
    <BrowserRouter>
    <App />
    </BrowserRouter>
)

const TRACKING_ID = "G-3L0JS609CD"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
