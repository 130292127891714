import React, { Component } from 'react'
import './contactus.css';
import Grid from '@material-ui/core/Grid';
import {Helmet} from 'react-helmet'
import Recaptcha from 'react-google-invisible-recaptcha';
import * as emailjs from 'emailjs-com';
import Footer from '../layouts/Footer';

//Google Analytics 4 Implementation Sahil
import ReactGA from 'react-ga4'; 
ReactGA.send({ hitType: "pageview", page: "./Contact" });

export default class Contact extends Component {
    constructor(){
        super();
        this.state={
            FirstName:'',
            LastName:'',
            Email:'',
            ConfirmEmail:'',
            message:'', 
        }
        this.handleFirstNameChange=this.handleFirstNameChange.bind(this)
        this.handleLastNameChange=this.handleLastNameChange.bind(this)
        this.handleEmailChange=this.handleEmailChange.bind(this)
        this.handleConfirmEmailChange=this.handleConfirmEmailChange.bind(this)
        this.handleMessageChange=this.handleMessageChange.bind(this)
    }
    handleFirstNameChange = (e) =>{
        this.setState({
            FirstName:e.target.value
        })
    }
    handleLastNameChange = (e) =>{
        this.setState({
            LastName:e.target.value
        })
    }
    handleEmailChange = (e) =>{
        this.setState({
            Email:e.target.value
        })
    }
    handleConfirmEmailChange = (e) =>{
        this.setState({
            ConfirmEmail:e.target.value
        })
    }
   
    handleMessageChange = (e) =>{
        this.setState({
            message:e.target.value
        })
    }
    showSubmitted=(e)=>{
        var x = document.getElementById('submittedtext');
        x.style.display='block'
        var y = document.getElementById('left');
        y.style.display='none'
        var z = document.getElementById('right');
        z.style.display='none'
      }

    handleSubmit=(e)=>{
        e.preventDefault()
       const {FirstName,LastName, Email, ConfirmEmail,message } = this.state
       if(FirstName.length && Email.length)
       if(Email==ConfirmEmail)
      {  
          emailjs.sendForm(
            "service_nqegey2",
            "template_lx27jlc",
            "ContactUSform",
            "r-HMomzlBMBtSXWTd"
        ).then()
        .catch();
        console.log("email sent !")
        this.setState({
            FirstName:'',
            LastName:'',
            Email:'',
            ConfirmEmail:'',
            message:'',
        });
        this.recaptcha.execute();
        this.showSubmitted();
    }
    else {
        alert("Email couldn't be Confirmed!");
            this.recaptcha.reset();
    }
        else {
            alert("Validation failed! Input cannot be empty");
            this.recaptcha.reset();
        }   
     };
    render() {
        return (
            <div className="root1">
                 <Helmet>
                    <title>Contact Us | St. Mary Kindergarten School</title>
                    <meta name="description"
                     content="Contact the school. Call us at 7015854844 to enquire more."/>
                     <meta name="robots" content="index, follow" />
        <meta
          name="googlebot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta
          name="bingbot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
                </Helmet>
                
                <div className="Header">
                    <h1 className="bigtext">CONTACT US</h1>
                </div>
                <form id="ContactUSform" > 
                <Grid className="formgrid" container spacing={0}>

                <Grid item xs={12} sm={6} className="left" id="left">
                   
                      <div className="form">
                       <p className="uplabel required">Name</p>
                       <Grid container spacing={2} className="firstFORM">
                     <Grid item xs={6} sm={3} className="first">
                    
                       <input className="forminput"
                       required
                         value="FirstName"
                           label="FirstName" 
                           name="FirstName"
                           id="FirstName"
                           value={this.state.FirstName}
                           type='text' 
                           onChange={this.handleFirstNameChange.bind(this)}/>
                          <p className="formlabel">First</p> 
                          
                          </Grid>
                     <Grid item xs={6} sm={3} className="second">
                        <input className="forminput"
                        value="LastName"
                        label="LastName" 
                        name="LastName"
                        id="LastName"
                        value={this.state.LastName}
                        type='text' 
                        onChange={this.handleLastNameChange.bind(this)}/>
                        <p className="formlabel">Last</p> 
                       
                        </Grid>
                        </Grid>
                          {/* </form>
                          
                    <form className="form"> */}
                   <br/>

                    <p className="downlabel required">Email</p>
                    <Grid container spacing={2} className="seconndFORM" style={{margin:"-8.5px"}}>
                        <Grid item xs={6} sm={3} className="first">
                  
                        <input className="forminput"
                        required
                         value="Email"
                           label="Email" 
                           name="Email"
                           id="Email"
                           value={this.state.Email}
                           type='text' 
                           onChange={this.handleEmailChange.bind(this)}/>
                           <p className="formlabel">Email</p> 
                           
                           </Grid>
                        <Grid item xs={6} sm={3} className="second">

                        <input 
                        className="forminput"
                        required
                        value="ConfirmEmail"
                          label="ConfirmEmail" 
                          name="ConfirmEmail"
                          id="ConfirmEmail"
                          value={this.state.ConfirmEmail}
                          type='text' 
                          onChange={this.handleConfirmEmailChange.bind(this)}/>
                          <p className="formlabel">Confirmed Email</p> 
                       
                          </Grid>

                          </Grid>
                          </div>
                          </Grid>

                         
                    {/* </form> */}
                  
                     
                <Grid item xs={12} sm={6}className="right" id="right">
  
                <div className="pair">
                    {/* <form id="ContactUSform"> */}
                <label className="messagelabel">Share Your Thoughts With Us!</label> 
                        <input value="message"
                        className="messageinput"
                        value="message"
                          name="message"
                          id="message"
                          value={this.state.message}
                          type='text' 
                          onChange={this.handleMessageChange.bind(this)}/>
                        
                          </div>
                          <button type="submit" onClick={this.handleSubmit.bind(this)} className="submit">SUBMIT</button>
                         
                     </Grid>
                    
                  
                     </Grid>

                     </form>
                     <div className="submittedtext" id="submittedtext"><h1>Thanks for contacting us! We will be in touch with you shortly.</h1></div>
                     <div className="reachUs">
                        <h1> REACH TO US </h1>
                        <p>M: 79882-25799</p>
                        <p>Ph: 01662-250454</p>
                        <p>st.mary250454@gmail.com</p>

                     </div>
                     <Recaptcha
          ref={ ref => this.recaptcha = ref }
          sitekey="6LcjpJcjAAAAALCsZZ8JgclWP67mfIYmjeynD3wt"
          onResolved={ () => console.log( 'Human detected.' ) }
          />    
          <Footer/>
            </div>
        )
    }
}
